import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Checkbox, Box, TableContainer, TextField, InputAdornment } from '@mui/material';
import { Search } from "@mui/icons-material"
import { useBff } from '../../../../utils/config';
import {capitalize} from '../../../../utils/helpers';



function SelectComparisons({ contrastOptions, formData, setFormData }: any) {
    const [selected, setSelected] = useState<any[]>([]);
    const [searchTerm, setSearchTerm] = useState('');

    function formatContrastOptions(contrastOptions: any[]) {
        return contrastOptions?.map(option => {
            const [controlValue, experimentalValue] = option.split(' - ').reverse();
            return {
                controlValue,
                experimentalValue
            };
        });
    }

    const data = formatContrastOptions(contrastOptions)?.filter(
    row => row?.controlValue?.toLowerCase().includes(searchTerm.toLowerCase()) || 
           row?.experimentalValue.toLowerCase().includes(searchTerm.toLowerCase())
  );
    const handleSelect = (index: number) => {
        const newSelected: any[] = [...selected];
        const selectedIndex = selected.indexOf(index);
        if (selectedIndex === -1) {
            newSelected.push(index);
        } else {
            newSelected.splice(selectedIndex, 1);
        }
        setSelected(newSelected);
    };

    const handleSelectAll = () => {
        if (selected.length < data.length) {
            setSelected(data.map((_, index) => index));
            return;
        }
        setSelected([]);
    };

    useEffect(() => {
        let values: any[] = []
        contrastOptions.map((d: any, i: any) => selected.includes(i) && values.push(d))
     setFormData({...formData, selected_contrast_options: values})
    }, [selected])
    return (
        <Box p={3} mr={5} sx={{ borderRadius: '12px', bgcolor: 'surface-container-lowest' }}>
             <TextField 
             sx={{ '& .MuiInputBase-input':{width:'200px', height:'35px', p:0}, mb:1 }}
        variant="outlined"
        placeholder="Search..."
        onChange={e => setSearchTerm(e.target.value)}
        InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
      />
            <TableContainer sx={{ border: '1px solid', borderColor: 'outline-variant', borderRadius: '6px' }}>
                <Table >
                    <TableHead>
                        <TableRow sx={{ bgcolor: 'surface-container-highest' }}>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    checked={data.length > 0 && selected.length === data.length}
                                    onChange={handleSelectAll}
                                />
                            </TableCell>
                            <TableCell >CONTROL VALUE</TableCell>
                            <TableCell>{' '}</TableCell>
                            <TableCell>EXPERIMENTAL VALUE</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row, index) => (
                            <TableRow key={index} onClick={() => handleSelect(index)} hover sx={{bgcolor: selected.includes(index) ? 'surface-container' : 'surface-container-lowest'}} >
                                <TableCell padding="checkbox">
                                    <Checkbox checked={selected.includes(index)} />
                                </TableCell>
                                <TableCell>{row.controlValue.split('_').map(capitalize).join(' and ')}</TableCell>
                                <TableCell>COMPARE TO</TableCell>
                                <TableCell>{row.experimentalValue.split('_').map(capitalize).join(' and ')}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default SelectComparisons;
