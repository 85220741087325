export const arrayRange = (start: number, stop: number, step: number) =>
  Array.from(
    { length: (stop - start) / step + 1 },
    (value, index) => start + index * step,
  );

/**
 * Capitalizes the first letter of a string.
 * @param str - the string to capitalize
 * @return the string with the first letter capitalized, empty string if other type
 */
export const capitalize = (str: string): string => {
  try {
    return str.charAt(0).toUpperCase() + str.slice(1);
  } catch (e) {
    return "";
  }
};
